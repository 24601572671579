<template lang="pug">
  .login
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'PageLogin',

  async mounted () {
    if (this.$route.query.open_app) {
      // go to android app
    }

    if (this.$route.query.id) {
      try {
        window?.ym?.(96913300, 'reachGoal', 'registration')
      } catch (e) {
        console.log(e)
      }
    }

    if (this.$route.query.token) {
      this.setToken(this.$route.query.token)

      try {
        const requests = [this.fetchUser()]
        const ref = this.$cookiz.get('ref') || window.localStorage.getItem('ref')

        if (ref) {
          requests.push(
            this.$axios.post('/user/account/referred', {
              ref
            })
          )
        }

        await Promise.all(requests)

        let redirectUrl = '/#social-login'
        if (this.$route.query.id) {
          redirectUrl += `?id=${this.$route.query.id}`
        }
        if (this.$route.query.redirect_to) {
          redirectUrl = this.$route.query.redirect_to
        }
        this.$router.push(this.localeLocation(redirectUrl))
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: login error ::`, e)
      }
    } else if (this.$route.query.error) {
      this.$router.push(
        this.localeLocation(
          `/#error=${this.$route.query.error}&error_data=${encodeURIComponent(
            this.$route.query.error_data
          )}`
        )
      )
    } else {
      this.$router.push(this.localeLocation('/#login'))
    }
  },

  methods: {
    ...mapActions('user', ['fetchUser']),
    ...mapMutations('user', ['setToken'])
  }
}
</script>
